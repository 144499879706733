/*eslint-disable */

import { mapGetters } from 'vuex';

export default {
  name: 'doctor-info-page',
  components: {
  },
  props: [],
  data () {
    return {
      doctorInfo:null,
      doctorId:''
    }
  },
  computed: {
    ...mapGetters([
      'getDoctorDetailByIdGetters',
    ]),
    doctorInfoByIdFun(){
      this.doctorInfo = this.$store.getters.getDoctorDetailByIdGetters.data;
      return;
    },
  },
  mounted () {
  },
  created(){
    this.doctorId = this.$route.params.doctorID 
  },
  methods: {
  },
  watch : {
    doctorInfoByIdFun(){
      return this.$store.getters.getDoctorDetailByIdGetters;
    },
  }
}