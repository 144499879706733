import { render, staticRenderFns } from "./doctor-info-page.html?vue&type=template&id=1f89a7a6&scoped=true&external"
import script from "./doctor-info-page.js?vue&type=script&lang=js&external"
export * from "./doctor-info-page.js?vue&type=script&lang=js&external"
import style0 from "./doctor-info-page.scss?vue&type=style&index=0&id=1f89a7a6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f89a7a6",
  null
  
)

export default component.exports